<template>
  <div>
    <div class="background-wrapper">
      <div class="background"></div>
    </div>

      <div class="teachers" id="teachers" name="teachers">
        <div class="teacher" id="teacher-bg-1">
          <div class="teacher-title-wrapper">
            <div class="teacher-title">
              <div class="title-description"></div>
              <h3 class="title-content">国内生涯规划咨询主要专家成员</h3>
            </div>
          </div>
          <div class="teacher-intro-wrapper">
            <div class="teacher-intro">
              <div class="intro-photo-wrapper">
                <div class="intro-photo photo-1"></div>
              </div>
              <div class="intro-text">
                <p>
                  <span class="intro-name">姚旎</span
                  ><span class="intro-position">生涯规划师</span>
                </p>
                <p>高校副教授</p>
                <p>研究生导师</p>
                <p>全国生涯委员会认证专家</p>
                <p>文化类志愿填报师</p>
                <p>国家二级心理咨询师</p>
                <p>咨询个案<span class="number">500+</span></p>
              </div>
            </div>
            <div class="teacher-intro">
              <div class="intro-photo-wrapper">
                <div class="intro-photo photo-2"></div>
              </div>
              <div class="intro-text">
                <p>
                  <span class="intro-name">张雪娇</span
                  ><span class="intro-position">艺体类志愿填报师</span>
                </p>
                <p>985高校文学博士</p>
                <p>新闻传播学博士后</p>
                <p>生涯规划导师</p>
                <p>至臻艺术高考志愿填报指导中心创始人</p>
                <p>艺体类咨询个案<span class="number">200+</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="teacher" id="teacher-bg-2">
          <div class="teacher-title-wrapper">
            <div class="teacher-title">
              <div class="title-description"></div>
              <h3 class="title-content">海外教育规划咨询主要专家成员</h3>
            </div>
          </div>
          <div class="teacher-intro-wrapper">
            <div class="teacher-intro">
              <div class="intro-photo-wrapper">
                <div class="intro-photo photo-3"></div>
              </div>
              <div class="intro-text">
                <p>
                  <span class="intro-name">刘洋</span
                  ><span class="intro-position">国际教育规划师</span>
                </p>
                <p>IEPA全球通留学规划师</p>
                <p>IEPA国际教育专业协会秘书长</p>
                <p>GNOSIS GROUP创始人</p>
                <p>14年VIP国际教育规划经历</p>
                <p>咨询案例<span class="number">500+</span></p>
              </div>
            </div>
            <div class="teacher-intro">
              <div class="intro-photo-wrapper">
                <div class="intro-photo photo-4"></div>
              </div>
              <div class="intro-text">
                <p>
                  <span class="intro-name">毕明</span
                  ><span class="intro-position">国际教育规划师</span>
                </p>
                <p>IEPA全球通留学规划师</p>
                <p>英国剑桥教育集团中国区国际交流合作代表</p>
                <p>8年华为工作经历</p>
                <p>国际教育咨询<span class="number">100+</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import $ from 'jquery'

var _hmt = _hmt || []
;(function () {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?5ca1e98131f5233c03d625b30305a73b'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

var setRem = function () {
  var designRem = 10
  var designWidth = 1280
  var designWidthMobile = 375
  var currentWidth = window.innerWidth
  var currentHeight = window.innerHeight
  var currentRem =
    currentWidth > currentHeight
      ? (designRem * currentWidth) / designWidth
      : (designRem * currentWidth) / designWidthMobile
  document.getElementsByTagName('html')[0].style.fontSize = currentRem + 'px'
}
setRem()
window.onresize = setRem

export default {
  name: '',
  data: function () {
    return {}
  },
  mounted: function () {},
}
</script>

<style lang="less">
@import url('./../../../../Public/resources/css/templates/home/index/main_members.min.css');
.intro-name,.intro-position,.intro-text p {
  color:#fff;
}
</style>
